import React from 'react';
import { useEffect, useState } from 'react';
import ApiService from '../lib/ApiService';
import {
	API_ENDPOINT_USER_SURVEYS,
	API_ENDPOINT_USERS
} from '../lib/apiEndpoints';
import { withRouter } from 'react-router-dom';
import SurveyList from '../components/SurveyList';
import { useSelector, connect } from 'react-redux';
import Loading from '../components/Loading';
import Account from '../components/user_account/Account';

const UserAccount = props => {
	const { user } = props;
	let user_id = props.match.params.user_id || user.user_id;

	const [surveyList, setSurveyList] = useState([]);
	const [userDetails, setUserDetails] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const fetchData = () => {
		setIsLoading(true);
		let surveyPromise = ApiService.request(
			'GET',
			`${API_ENDPOINT_USER_SURVEYS}/${user_id}`
		);
		let userPromise = ApiService.request(
			'GET',
			`${API_ENDPOINT_USERS}/${user_id}`
		);
		Promise.all([surveyPromise, userPromise])
			.then(([surveyResponse, userResponse]) => {
				setSurveyList(surveyResponse.data);
				setUserDetails(userResponse.data);
				setIsLoading(false);
			})
			.catch(err => console.log(err), setIsLoading(false));
	};
	useEffect(() => {
		fetchData();
	}, []);
	const onDownload = () => {
		alert('Downloaded');
	};
	const onFormSubmit = (values, actions) => {};
	if (isLoading) {
		return <Loading />;
	}
	return (
		<div className='container acct-main-div'>
			<h3 className='text-center font-weight-bold text-capitalize bmain-tit'>
				Welcome, {user.first_name} {user.last_name}
			</h3>
			<SurveyList surveys={surveyList} />
			<Account user_id={user_id} user={user} />
		</div>
	);
};

const mapStateToProps = state => {
	return { user: state.user };
};
export default withRouter(connect(mapStateToProps, {})(UserAccount));
