import React, { useState, useEffect } from 'react';
import SurveyReportBox from '../components/surveyReport/SurveyReportBox';
import ApiService from '../lib/ApiService';
import Loading from '../components/Loading';
import TotalScore from '../components/surveyReport/TotalScore';
import Report from '../components/surveyReport/Report';
const SurveyReport = props => {
	console.log("_props",props)
	const { survey_id } = props.match.params;
	const [isLoading, setIsLoading] = useState(true);
	const [surveyReport, setSurveyReport] = useState(null);
	const [surveyScore, setSurveyScore] = useState(null);
	useEffect(() => {
		const TotalScorePromise = ApiService.request(
			'GET',
			`surveys/${survey_id}/total_score`
		);
		const ReportPromise = ApiService.request(
			'GET',
			`surveys/${survey_id}/report`
		);
		Promise.all([TotalScorePromise, ReportPromise]).then(
			([totalScoreReponse, reportResponse]) => {
				setSurveyScore(totalScoreReponse.data);
				setSurveyReport(reportResponse.data);
				setIsLoading(false);
			}
		);
	}, []);
	if (isLoading) {
		return <Loading />;
	}

	return (
		<section className='survey-report mb-5'>
			<div className='print-logo text-center mb-4 d-none'>
				<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/logo.png' />
			</div>
			{surveyScore !== null ? (
				<TotalScore
					survey_id={survey_id}
					total_score={surveyScore}
					show_details={false}
				/>
			) : null}
			{surveyReport !== null ? <Report report={surveyReport} /> : null}
			<SurveyReportBox survey_id={survey_id} />
		</section>
	);
};

export default SurveyReport;
