import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const Footer = props => {
	const { isUserLoggedIn } = useSelector(state => state.user);
	return (
		<footer className='footer-wrapper bg-dark text-light pt-5 pb-5'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-3'>
						<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/logo.png' />
					</div>
					<div className='col-md-4 d-flex justify-content-end foot-btn'></div>
					<div className='col-md-5'>
						<div
							className='d-flex justify-content-around
						 flex-wrap footer-items-hide'>
							{/* deleted className='flex-fill' from all a tag below */}
							<a href='https://bhc.dukeheights.ca' target='_blank'>
								Home
							</a>
							<a href='https://dukeheights.ca/duke-heights-bia/' className='abc' target='_blank'>
								About
							</a>
							{/* <a href='#' className='abc'>
								Sample Report
							</a> */}
							<a href='https://bhc.dukeheights.ca/contact-us/' className='abc' target='_blank'>
								Contact Us
							</a>
							<a href={isUserLoggedIn ? `/account` : `/login`}>My Account</a>
						</div>
						<div className='d-flex mt-4 justify-content-end social-ico mr-3'>
							<div className='mr-2 social' style={{ color: '#c2c2c2' }}>
								Connect with us
							</div>
							<div className='mr-2 align-self-center'>
								<a
									href='https://www.facebook.com/DUKEHeightsBIA/'
									target='_blank'
									className='social-links'>
									<FontAwesomeIcon icon={['fab', 'facebook']} />
								</a>
								<span className='ml-2 text-muted'>|</span>
							</div>
							<div className='mr-2'>
								<a
									href='https://twitter.com/DUKEHeightsBIA'
									target='_blank'
									className='social-links'>
									<FontAwesomeIcon icon={['fab', 'twitter']} />
								</a>
								<span className='ml-2 text-muted'>|</span>
							</div>
							<div className='mr-2'>
								<a
									href='https://www.linkedin.com/company/duke-heights-business-improvement-area-bia/'
									className='flex-fill social-links'
									target='_blank'>
									<FontAwesomeIcon icon={['fab', 'linkedin']} />
								</a>
								{/* <span className='ml-2 text-muted'>|</span> */}
							</div>
							{/* <div className='mr-2'>
								<a
									href='https://www.instagram.com/dukeheightsbia/'
									className='flex-fill social-links'
									target='_blank'>
									<FontAwesomeIcon icon={['fab', 'instagram']} />
								</a>
							</div> */}
						</div>
					</div>
					{/* <div className='col-md-3 d-flex justify-content-end foot-btn'>
						<div>
							<button
								type='button'
								className='btn btn-lg btn-primary start-now-button'>
								START NOW
							</button>
						</div>
					</div> */}
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {};

export default Footer;
