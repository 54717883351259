import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal, closeAllModals } from '../../redux/actions/modalAction';
//import { mapStateToProps, mapDispatchToProps } from '../redux/containerProps';
import AppPortal from '../AppPortal';
import Modal from './Modal';
// const AysncModalContainer = () =>
//   Loadabel({
//     loader: () => import('../../../theme/containers/modal'),
//     loading: () => <h2>Loading....</h2>
//   });
class Modals extends React.Component {
  componentDidMount() {
    this.props.history.listen((location, action) => {
      // location is an object like window.location
      this.props.closeAllModals();
    });
  }
  render() {
    const modals = this.props.modals
      ? this.props.modals.map((item, i) => (
          <AppPortal key={i}>
            <Modal item={item} onClose={item => this.props.closeModal(item)} />
          </AppPortal>
        ))
      : '';
    return <div className={modals.length > 0 ? 'modals' : ''}>{modals}</div>;
    //return "<div className={modals.length > 0 ? 'modals' : ''}>{modals}</div>";
  }
}
const mapStateToProps = state => {
  return {
    //state: state.app,
    modals: state.modals,
    toasts: state.toasts
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { closeModal, closeAllModals }
  )(Modals)
);
