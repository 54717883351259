import React from 'react';
import PropTypes from 'prop-types';
import '../../../src/css/custom.scss';
import { PageLink } from 'lib/pageLink';
import { withRouter } from 'react-router-dom';

function SurveyReportBox(props) {
	
	const downloadReport = () => {
		window.print();
	};

	const onDownload = () => {
		props.history.push(PageLink.surveyReport(props?.survey_id?.id));
	};

	return (
		<div className='container justify-content-center d-flex get-report-box'>
			<div
				className={`d-flex w-75 ${props.background} pt-2 pb-2 justify-content-around align-content-center`}>
				<div class='box-img'>
					<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/03/get-your-report.png' />
				</div>
				<div className='align-self-center'>
					<h3 className='text-danger font-weight-bold mr-3' style={{fontSize:'26px'}}>
						{props.total_score
							? 'Your Latest Score: ' + props.total_score 
							: 'Get Your Report'}
					</h3>
					<div className='get-desc'>
						{/* Lorem ipsum dolor sit amet, consectetur adipiscing elit */}
					</div>
				</div>
				<div className='align-self-center mr-2'>
					{props?.viewresult?
					<button
						type='button'
						className='btn btn-lg btn-primary'
						// onClick={downloadReport}
						onClick={onDownload.bind()}
						style={{fontSize:'13px'}}
					>
						View Result
					</button>
					:
					<button
						type='button'
						className='btn btn-lg btn-primary'
						onClick={downloadReport}>
						Download
					</button>}
				</div>
			</div>
		</div>
	);
}

SurveyReportBox.defaultProps = {
	background: 'bg-light-blue'
};
SurveyReportBox.propTypes = {
	survey_id: PropTypes.any.isRequired,
	total_score: PropTypes.number,
	background: PropTypes.string
};

export default withRouter(SurveyReportBox);
