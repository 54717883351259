import React from 'react';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import ApiService from 'lib/ApiService';

const validationSchema = Yup.object().shape({
	company_name: Yup.string().required('Company name is required'),
	address: Yup.string().required('Company address is required'),
	city: Yup.string().required('City is required'),
	postal_code: Yup.string().required('Postal code is required')
	// industry: Yup.string().required('Industry type is required')
});

export default function Company({ user_id }) {
	const onSubmit = async (values, actions) => {
		try {
			await ApiService.request('PUT', `users/${user_id}`, values, true);
			actions.setSubmitting(false);
			alert('Company detials updated successfully !');
			actions.resetForm();
		} catch (err) {
			actions.setSubmitting(false);
		}
	};
	return (
		<div className='form-box company mt-4'>
			<div className='form-title font-weight-bold text-uppercase mb-3'>
				Company Information
			</div>
			<div className='row'>
				<div className='col-md-4'>
					Tell us a little about your business
				</div>
				<div className='col-md-8'>
					<Formik
						validationSchema={validationSchema}
						initialValues={{
							company_name: '',
							address: '',
							city: '',
							postal_code: ''
							// industry: ''
						}}
						onSubmit={onSubmit}>
						{({ errors, touched, isValid, isSubmitting, resetForm }) => (
							<>
								<Form>
									<div className='form-row'>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='company_name'
												placeholder='Company Name'
												className='form-control'
											/>

											{errors.company_name && touched.company_name && (
												<small>{errors.company_name}</small>
											)}
										</div>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='address'
												placeholder='Address'
												className='form-control'
											/>

											{errors.address && touched.address && (
												<small>{errors.address}</small>
											)}
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='city'
												placeholder='City'
												className='form-control'
											/>

											{errors.city && touched.city && <small>{errors.city}</small>}
										</div>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='postal_code'
												placeholder='Postal Code'
												className='form-control'
											/>

											{errors.postal_code && touched.postal_code && (
												<small>{errors.postal_code}</small>
											)}
										</div>
										{/* <div className='form-group col-sm-6'>
											<Field
												type='text'
												name='industry'
												placeholder='Industry'
												className='form-control'
											/>

											{errors.industry && touched.industry && (
												<small>{errors.industry}</small>
											)}
										</div> */}
									</div>
									<div className='d-flex justify-content-end'>
										<button
											className='btn btn-lg btn-primary save-button'
											type='submit'
											disabled={!isValid || isSubmitting}>
											SAVE
										</button>
									</div>
								</Form>
							</>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
