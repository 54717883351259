import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApiService from '../../lib/ApiService';
import Loading from '../Loading';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import SurveyQuestion from './SurveyQuestion';

const questionnairesSchema = (questions = []) => {
	let questionsSchema = {};
	questions.forEach(question => {
		let format;
		switch (question.input_type) {
			case 'radio':
			case 'dropdown':
				format = Yup.number();
				break;
			case 'checkbox':
			case 'multi-select':
				format = Yup.array();
				break;
			default:
				format = Yup.string();
				break;
		}
		questionsSchema[`question_${question.id}`] = format
			.required('This field is required')
			.nullable();
	});
	return Yup.object().shape(questionsSchema);
};
const setInitialValues = (questions = [], answers) => {
	let initialValues = {};
	questions.forEach(question => {
		initialValues[`question_${question.id}`] = getAnswer(question.id, answers);
	});
	return initialValues;
};
const getAnswer = (question_id, answers = []) => {
	const answerObj = answers.find(obj => obj.question_id === question_id);
	return answerObj ? answerObj.answer : null;
};

const SurveyStepForm = props => {
	const { survey_id, step, onNext, onBack, isLast, isFirst } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [questionnaires, setQuestionnaires] = useState([]);
	const [answers, setAnswers] = useState([]);
	const fetchStepQuestionnaires = () => {
		setIsLoading(true);
		ApiService.request('GET', `surveys/${survey_id}/steps/${step.id}/questions`)
			.then(response => {
				setQuestionnaires(response.data.questions);
				if (response.data.answers) {
					setAnswers(response.data.answers);
				}
			})
			.finally(() => setIsLoading(false));
	};
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [questionnaires]);
	useEffect(() => {
		fetchStepQuestionnaires();
	}, [step.id]);
	const onFormSubmit = (values, actions) => {
		let newAnswers = [];
		Object.entries(values).forEach(([quesion_id, answer]) => {
			const id = Number(quesion_id.replace('question_', ''));
			//newAnswers = [...newAnswers, { question_id: id, answer: answer }];
			let answerIndex = answers.findIndex(obj => obj.question_id === id);
			let question = questionnaires.find(question => question.id === id);
			let value = question.input_type === 'text' ? answer : Number(answer);
			if (answerIndex < 0) {
				newAnswers = [...newAnswers, { question_id: id, answer: value }];
			} else {
				newAnswers[id] = { question_id: id, answer: value };
			}
		});
		ApiService.request(
			'POST',
			`surveys/${survey_id}/steps/${step.id}/questions`,
			newAnswers
		)
			.then(response => onNext())
			.catch(err => console.log(err))
			.finally(() => actions.setSubmitting(false));
	};
	if (isLoading) {
		return <Loading />;
	}
	if (!questionnaires) {
		return null;
	}

	return (
		<>
			<h2 className='text-primary'>{step.name}</h2>
			<Formik
				isInitialValid={false}
				validationSchema={questionnairesSchema(questionnaires)}
				initialValues={setInitialValues(questionnaires, answers)}
				onSubmit={onFormSubmit}>
				{({ isSubmitting, isValid, values, submitForm, errors, touched }) => (
					<Form>
						{questionnaires.map((question, index) => (
							<SurveyQuestion
								values={values}
								key={question.id}
								errors={errors}
								touched={touched}
								index={index + 1}
								question={question}
							/>
						))}
						<div className='d-flex mt-2 mb-2 back-start'>
							{!isFirst ? (
								<button
									type='button'
									className='btn btn-primary mr-4 back-btn'
									onClick={onBack}>
									Back
								</button>
							) : null}

							{!isLast ? (
								<button
									type='submit'
									disabled={isSubmitting || !isValid}
									className='btn btn-primary next-btn'>
									{isSubmitting ? 'Please Wait' : 'Next'}
								</button>
							) : null}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

SurveyStepForm.propTypes = {
	survey_id: PropTypes.any.isRequired,
	step: PropTypes.object.isRequired,
	onNext: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired,
	isLast: PropTypes.bool.isRequired,
	isFirst: PropTypes.bool.isRequired
};

export default SurveyStepForm;
