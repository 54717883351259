import {
	USER_LOGIN,
	USER_REGISTER,
	USER_LOGOUT,
	USER_UPDATE
} from '../actionTypes';

export function userReducer(state = {}, action) {
	switch (action.type) {
		case USER_LOGIN:
		case USER_REGISTER:
			return { ...action.payload, isUserLoggedIn: true };
		case USER_LOGOUT:
			return { isUserLoggedIn: false, ...action.payload };
		case USER_UPDATE:
			return { ...state, isUserLoggedIn: true, ...action.payload };
		default:
			return state;
	}
}
