import React from 'react';
import PropTypes from 'prop-types';
import InputFeedback from './InputFeedback';
import { Field } from 'formik';

const TextInput = ({ index, value, error, touched, id, label }) => {
	return <Field className='form-control' type='text' name={id} />;
};

TextInput.propTypes = {
	value: PropTypes.any,
	error: PropTypes.any,
	touched: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
};

export default TextInput;
