import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ApiService from '../lib/ApiService';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SurveyProgress from '../components/surveyForm/SurveyProgress';
import SurveyStepForm from '../components/surveyForm/SurveyStepForm';
import { useHistory } from 'react-router-dom';
import { PageLink } from 'lib/pageLink';
const SurveyForm = props => {
	const { survey_id } = props.match.params;
	const [isLoading, setIsLoading] = useState(true);
	const [surveySteps, setSurveySteps] = useState([]);
	const [activeStepIndex, setActiveStepIndex] = useState(null);
	const history = useHistory();
	const fetchData = () => {
		setIsLoading(true);
		ApiService.request('GET', `surveys/${survey_id}/steps`).then(response => {
			setSurveySteps(response.data);
			setIsLoading(false);
			const activeIndex = response.data.findIndex(step => !step.status);
			if (activeIndex === -1) {
				history.push(PageLink.surveyScore(survey_id));
			} else {
				setActiveStepIndex(activeIndex);
			}
		});
	};
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {}, [surveySteps]);
	if (isLoading) {
		return <Loading />;
	}
	const goToNextStep = () => {
		let steps = [...surveySteps];
		steps[activeStepIndex] = { ...surveySteps[activeStepIndex], status: true };
		setSurveySteps(steps);
		let newStepIndex = activeStepIndex + 1;
		if (newStepIndex >= surveySteps.length) {
			history.push(PageLink.surveyScore(survey_id));
		} else {
			setActiveStepIndex(activeStepIndex + 1);
		}
	};
	const goToPreviousStep = () => {
		if (activeStepIndex !== 0) {
			setActiveStepIndex(activeStepIndex - 1);
		}
	};
	return (
		<div className='container getscore-cont'>
			<div className='row'>
				<div className='col-md-12 survey-tit'>
					<h2 className='font-weight-bold bmain-tit'>Get Your Score</h2>
				</div>
				<div className='col-md-4 survey-steps'>
					<div className='list-group list-group-flush'>
						{surveySteps.map(step => (
							<a
								key={step.id}
								className='list-group-item justify-content-between list-group-item-action'>
								<span className={step.status ? 'text-primary' : ''}>{step.name}</span>
								{step.status ? (
									<FontAwesomeIcon className='text-primary ml-3' icon='check' />
								) : null}
							</a>
						))}
						<a className='list-group-item justify-content-between list-group-item-action'>
							<span>Your Result</span>
						</a>
					</div>
				</div>
				<div className='col-md-8 survey-quest-cont'>
					<SurveyProgress steps={surveySteps} />

					{activeStepIndex !== null ? (
						<SurveyStepForm
							step={surveySteps[activeStepIndex]}
							survey_id={survey_id}
							onNext={goToNextStep}
							onBack={goToPreviousStep}
							islast={activeStepIndex === surveySteps.length - 1}
							isFirst={activeStepIndex === 0}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

SurveyForm.propTypes = {};

export default SurveyForm;
