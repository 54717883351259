import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import classNames from 'classnames';
import AuthService from '../lib/AuthService';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const RegisterValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Please enter a valid Email address')
		.required('Please enter your email address')
});

export const ForgetPassword = props => {
	const emailRef = React.createRef();
	const [errorMessage, setErrorMessage] = useState(null);

	const onFormSubmit = (values, actions) => {
		let data = {
			...values,
			email: values.email.toLocaleLowerCase()
		};
		setErrorMessage(null);
		//alert(JSON.stringify(values));
		AuthService.passwordUpdateEmail(data)
			.then(resp => {
				props.history.push('/reset-message');
			})
			.catch(err => setErrorMessage(err.message))
			.finally(() => actions.setSubmitting(false));
	};

	return (
		<>
			<div className='container login-cont create-acc-cont'>
				<div className='row align-middle mt-5 mb-5'>
					<div className='col-md-6 reset-column'>
						<div className='card bg-light-blue'>
							<div className='card-body forgot-card-body'>
								<div className='card-title'>
									<h1 className='text-center mb-4'>Forgot your password ?</h1>

									<p className='mb-0 text-center'>
										Enter you email address below and we'll get you back on track.
									</p>
								</div>
								{errorMessage ? (
									<p className='text-center p-2 text-center border border-danger'>
										{errorMessage}
									</p>
								) : null}
								<Formik
									validationSchema={RegisterValidationSchema}
									initialValues={{
										email: ''
									}}
									onSubmit={onFormSubmit}>
									{({ errors, touched, isValid, isSubmitting }) => (
										<>
											<Form className='loginreg-frm'>
												<div className='form-group'>
													<Field
														type='email'
														name='email'
														placeholder='yourmail@email.com'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.email && errors.email
														})}
													/>

													{errors.email && touched.email && <small>{errors.email}</small>}
												</div>

												<button
													className='btn btn-primary create-button'
													style={{ marginBottom: '10px' }}
													type='submit'
													disabled={!isValid || isSubmitting}>
													Send
												</button>
												<br></br>
												<div className='account-to'>
													<NavLink to='/Login' className='navigate'>
														Back to sign in
													</NavLink>
												</div>
											</Form>
										</>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
