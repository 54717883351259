import React from 'react';
import PropTypes from 'prop-types';
import AuthService from 'lib/AuthService';
import { useSelector } from 'react-redux';

const HeaderMenu = ({ showMobileMenu }) => {
	const { isUserLoggedIn } = useSelector(state => state.user);
	return (
		<div
			className={` collapse navbar-collapse ${
				showMobileMenu ? 'show' : ''
			}  navigate-collapse `}
			id='navbarNavAltMarkup'>
			<div
				className='navbar-nav mr-auto mt-2 mt-lg-0'
				style={{ width: '75%', justifyContent: 'center' }}>
				<a
					className='nav-item nav-link active'
					href='https://bhc.dukeheights.ca'
					target='_blank'>
					Home <span className='sr-only'>(current)</span>
				</a>
				<a
					className='nav-item nav-link'
					href={isUserLoggedIn ? `/account` : `/login`}>
					My Account
				</a>
				{/* <a className='nav-item nav-link' href='#'>
					Pricing
				</a> */}
			</div>
			{isUserLoggedIn ? (
				<form className='form-inline my-2 my-lg-0'>
					<button
						type='button'
						className='btn btn-danger'
						onClick={() => AuthService.signout()}>
						Logout
					</button>
				</form>
			) : null}
		</div>
	);
};

HeaderMenu.propTypes = {};

export default HeaderMenu;
