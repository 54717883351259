import axios, { AxiosRequestConfig, Method } from 'axios';
import store from '../redux/store';
import { setUserDetails } from '../lib/AuthService';
import {
	showSuccessToast,
	showErrorToast
} from '../redux/actions/toastsAction';
const Baseurl = {
	url: process.env.REACT_APP_API_URL
};
export default {
	request(method = 'POST', endpoint, data = {}, showErrorAlert = true) {
		const token = store.getState().user.token || '';
		//let token = localStorage.getItem(USER_TOKEN);

		let requestParams = {
			url: Baseurl.url + endpoint,
			method: method,
			data: data
		};
		let isFormData = data instanceof FormData ? true : false;
		let headers = {
			'Content-Type': !isFormData
				? 'application/json;charset=UTF-8'
				: 'multipart/form-data'
		};

		if (token) {
			Object.assign(headers, { Authorization: `Bearer ${token}` });
		}
		Object.assign(requestParams, { headers: { ...headers } });
		let params = isFormData ? data : JSON.stringify(data);
		if (method === 'GET') {
			Object.assign(requestParams, {
				params: data
			});
		} else if (data) {
			Object.assign(requestParams, {
				data: params
			});
		}
		// else {
		//   Object.assign(requestParams, { params: params });
		// }
		return new Promise((resolve, reject) => {
			axios(requestParams)
				//.then(resp => resp.data.json())
				.then(
					resp => {
						if (resp.data.status === true) {
							handleApiSuccess(resp.data, resolve);
						} else {
							handleApiError(resp.data, showErrorAlert, reject);
						}
					},
					err => handleApiError(err, showErrorAlert, reject)
				);
		});
	},
	uploadImage(data, showErrorAlert = false) {
		return new Promise((resolve, reject) => {
			axios
				.post(Baseurl.url + '/images', data, {
					headers: {
						accept: 'application/json',
						'Accept-Language': 'en-US,en;q=0.8',
						'Content-Type': `multipart/form-data;`
					}
				})
				.then(resp => {
					if (resp.data.status === true) {
						handleApiSuccess(resp.data.data.images, resolve);
					} else {
						handleApiError(resp.data, showErrorAlert, reject);
					}
				})
				.catch(err => {});
		});
	}
};
const handleApiError = (err, showAlert, reject) => {
	if (showAlert) {
		let msg = err.message
			? err.message
			: 'Something went wrong, please try again later';
		store.dispatch(showErrorToast(msg));
	}
	if (reject) {
		reject(err);
	}
};
const handleApiSuccess = (resp, resolve) => {
	if (resp.message && resp.message !== 'success') {
		store.dispatch(showSuccessToast(resp.message));
	}
	if (resolve) {
		resolve(resp);
		// store.dispatch();
		// setUserDetails(resp.data);
	}
};
