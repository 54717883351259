import React from 'react';
import PropTypes from 'prop-types';
import InputFeedback from './InputFeedback';
import { Field } from 'formik';

// Radio input
const RadioButton = ({
	field: { name, value, onChange, onBlur },
	id,
	label,
	...props
}) => {
	return (
		<div className='form-check'>
			<input
				name={name}
				type='radio'
				value={id} // could be something else for output?
				checked={id == value}
				onChange={onChange}
				onBlur={onBlur}
				className='form-check-input'
				id={`option_${id}`}
				{...props}
			/>
			<label className='form-check-label' htmlFor={`option_${id}`}>
				{label}
			</label>
		</div>
	);
};

// Radio group
const RadioButtonGroup = ({
	index,
	value,
	error,
	touched,
	id,
	label,
	options = []
}) => {
	return options.map(option => (
		<Field
			component={RadioButton}
			name={id}
			id={option.id}
			label={option.label}
			key={option.id}
		/>
	));
};

RadioButtonGroup.propTypes = {
	index: PropTypes.number.isRequired,
	value: PropTypes.any,
	error: PropTypes.any,
	touched: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired
};

export default RadioButtonGroup;
