const rootPath = '';
export const PageLink = {
	surveyReport: survey_id => {
		return `${rootPath}/surveys/${survey_id}/report`;
	},
	surveyForm: (survey_id, step = '') => {
		return `${rootPath}/surveys/${survey_id}/${step}`;
	},
	surveyScore: survey_id => {
		return `${rootPath}/surveys/${survey_id}/score`;
	},
	userAccount: (user_id = '') => {
		return `${rootPath}/users/account/${user_id}`;
	},
	register: () => {
		return `${rootPath}/register`;
	}
};
