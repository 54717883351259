export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALs = 'CLOSE_ALL_MODALS';

//User Actions
export const USER_LOGIN = 'USER_LOGIN';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REGISTER = 'USER_REGISTER';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';
export const AUTHENTICATE = 'AUTHENTICATE';
