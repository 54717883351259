import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import classNames from 'classnames';
import AuthService from '../lib/AuthService';

const RegisterValidationSchema = Yup.object().shape({
	password: Yup.string()
		.required('Password is required')
		.min(6, 'Password needs to minimum 6 characters'),
	confirm_password: Yup.string()
		.required('Re-enter password')
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const ResetPassword = props => {
	const [errorMessage, setErrorMessage] = useState(null);
	const token_id = props?.match?.params?.token;
	// const password_token = token_id?.slice(6);
	const password_token = token_id;

	console.log("token_id",token_id)
	console.log("password_token",password_token)

	// console.log(password_token);
	const onFormSubmit = (values, actions) => {
		let data = {
			password_token,
			...values
		};
		//alert(JSON.stringify(values));
		//update password API call
		AuthService.update(data)
			.then(resp => {
				// setErrorMessage(resp.message);
				setTimeout(() => {
					props.history.push('/password-update-success');
				}, 5000);
			})
			.catch(err => setErrorMessage(err.message))
			.finally(() => actions.setSubmitting(false));
	};
	return (
		<>
			<div className='container login-cont create-acc-cont'>
				<div className='row align-middle mt-5 mb-5'>
					<div className='col-md-6 reset-column'>
						<div className='card bg-light-blue'>
							<div className='card-body reset-password'>
								<div className='card-title'>
									<h1 className='text-center'>Update Password</h1>
								</div>
								{/* {errorMessage ? (
									<p className='text-center p-2 text-center border border-danger'>
										{errorMessage}
									</p>
								) : null} */}
								<Formik
									validationSchema={RegisterValidationSchema}
									initialValues={{
										password: '',
										confirm_password: ''
									}}
									onSubmit={onFormSubmit}>
									{({ errors, touched, isValid, isSubmitting }) => (
										<>
											<Form className='loginreg-frm'>
												<div className='form-group'>
													<Field
														type='password'
														name='password'
														placeholder='New password'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.password && errors.password
														})}
													/>

													{errors.password && touched.password && (
														<small>{errors.password}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='password'
														name='confirm_password'
														placeholder='Confirm Password'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.confirm_password && errors.confirm_password
														})}
													/>

													{errors.confirm_password && touched.confirm_password && (
														<small>{errors.confirm_password}</small>
													)}
												</div>

												<button
													className='btn btn-primary create-button'
													type='submit'
													disabled={!isValid || isSubmitting}>
													Update password
												</button>
												<br></br>
											</Form>
										</>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withRouter(ResetPassword);
