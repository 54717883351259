import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ApiService from 'lib/ApiService';

const validationSchema = Yup.object().shape({
	password: Yup.string().required('Password is required'),
	new_password: Yup.string()
		.required('Please enter a new password')
		.min(8, 'Password should be of length minimum 8 characters'),
	confirm_new_password: Yup.string().oneOf(
		[Yup.ref('new_password'), null],
		'Passwords must match'
	)
});

export default function Password({ user_id }) {
	const onSubmit = async (values, actions) => {
		try {
			await ApiService.request('PUT', `users/password`, values, true);
			actions.setSubmitting(false);
			alert('Password updated successfully !');
			actions.resetForm();
		} catch (err) {
			actions.setSubmitting(false);
			alert('Current password not match');
		}
	};
	return (
		<div className='form-box password mt-4'>
			<div className='form-title font-weight-bold text-uppercase mb-3'>
				Password
			</div>
			<div className='row'>
				<div className='col-md-4'>
					You can easily reset your password here. Keep your account secured and safe!
				</div>
				<div className='col-md-8'>
					<Formik
						validationSchema={validationSchema}
						initialValues={{
							password: '',
							new_password: '',
							confirm_new_password: ''
						}}
						onSubmit={onSubmit}>
						{({ errors, touched, isValid, isSubmitting, resetForm }) => (
							<>
								<Form>
									<div className='form-row'>
										<div className='form-group col-sm-6'>
											<Field
												type='password'
												name='password'
												placeholder='Current Password'
												className='form-control'
											/>

											{errors.password && touched.password && (
												<small>{errors.password}</small>
											)}
										</div>
										<div className='form-group col-sm-6'>
											<Field
												type='password'
												name='new_password'
												placeholder='New Password'
												className='form-control'
											/>

											{errors.new_password && touched.new_password && (
												<small>{errors.new_password}</small>
											)}
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-sm-6 offset-6 confirm-pass'>
											<Field
												type='password'
												name='confirm_new_password'
												placeholder='Confirm Password'
												className='form-control'
											/>

											{errors.confirm_new_password && touched.confirm_new_password && (
												<small>{errors.confirm_new_password}</small>
											)}
										</div>
									</div>
									<div className='d-flex justify-content-end'>
										<button
											className='btn btn-lg btn-primary save-button'
											type='submit'
											disabled={!isValid || isSubmitting}>
											SAVE
										</button>
									</div>
								</Form>
							</>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
