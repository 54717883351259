import React, { useState } from 'react';
import { Provider, useSelector } from 'react-redux';
// import logo from '../logo.jpg';
import { withRouter } from 'react-router-dom';
import AuthService from '../lib/AuthService';
import { NavLink } from 'react-router-dom';
import { PageLink } from '../lib/pageLink';
import Header from 'components/header/Header';
const Login = props => {
	const emailRef = React.createRef();
	const passwordRef = React.createRef();

	const onLogin = event => {
		event.preventDefault();
		let email = emailRef.current.value;
		let password = passwordRef.current.value;

		if (!email) {
			alert('Please enter a username or email ID');
			return;
		}
		if (!password) {
			alert('Please enter a password');
			return;
		}

		//Call authenticate method of AuthService constant and pass email, password and callback function
		AuthService.authenticate({ email, password }).then(resp => {
			//toggleLoggedIn(true);
			if (resp.data.role == 'admin') {
				props.history.push('user-list');
			} else {
				props.history.push('account', { user_id: resp.data.user_id });
			}
		});
	};
	// const { isLoggedIn } = useSelector(state => state.user);
	const renderLoginForm = () => {
		return (
			<>
				{/* <div className='loign-header'>
					<Header />
				</div> */}
				<div className='login-header'>
					<header className='position-relative container'>
						<nav className='navbar navbar-expand-lg navbar-light '>
							<a className='navbar-brand' href='https://bhc.dukeheights.ca/'>
								<img
									src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/logo.png'
									className='d-inline-block align-top'
								/>
							</a>
						</nav>
					</header>
				</div>

				<div className='container login-cont logv1 login'>
					<div className='row align-middle mt-5'>
						<div className='col-md-6 '>
							<div className='card bg-light-blue '>
								<div className='card-body'>
									{/* {!isLoggedIn ? (
									<>
										<h1 className='text-center'>Login your account</h1>
										<p className='text-center login-subtxt'>
											Lorem Ipsum is simply dummy text of
											<br /> the printing an
										</p>
									</>
								) : (
									<>
										{' '}
										<h1 className='text-center'>Login account</h1>
										<p className='text-center login-subtxt'>
											Lorem Ipsum is simply dummy text of
											<br /> the printing an
										</p>
									</>
								)} */}

									<h1 className='text-center'>Login your account</h1>
									<p className='text-center login-subtxt'>
									</p>

									<form onSubmit={onLogin} className='loginreg-frm'>
										<div className='form-group'>
											<label htmlFor='email'>Email</label>
											<input
												ref={emailRef}
												name='email'
												type='text'
												id='email'
												className='form-control'
												placeholder='Email'
											/>
										</div>
										<div className='form-group'>
											<label htmlFor='password'>Password</label>
											<input
												ref={passwordRef}
												name='password'
												type='password'
												id='password'
												className='form-control'
												placeholder='Password'
											/>
										</div>
										<div className='login'>
											<button type='submit' className='btn btn-primary login-button'>
												Sign in
											</button>
											{/* <button
											className='btn btn-primary register-button'
											style={{ marginLeft: 20 }}
											onClick={() => props.history.push('register')}>
											Register as New User
										</button> */}
										</div>
										<div className='forgot'>
											<NavLink to='/email-password' className='navigate create-account'>
												Forgot password?
											</NavLink>
										</div>
										<div className='register-link text-center'>
											<NavLink to={PageLink.register} className='navigate' style={{fontSize:'18px',fontWeight:'bold'}}>
												Or, create an account
											</NavLink>
										</div>
									</form>
								</div>
							</div>
							<div className='logo mb-4'>
								{/* <img src={logo} alt='logo' className='mx-auto d-block' /> */}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	//get from(referrer) from props.location.state if set

	//render login form if user not logged in
	return renderLoginForm();
};

export default withRouter(Login);
