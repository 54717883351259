import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
const defaultStore = {
  toasts: [],
  modals: [],
  user: {}
};
//Create a redux store by passing the combined reducers or a single reducer
const store = createStore(
  reducers,
  defaultStore,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // compose(
  //   applyMiddleware(thunk),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )

  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
