import React, { Component } from 'react';
import Table from 'components/tableList';
import ApiService from 'lib/ApiService';
import { MDBInput } from 'mdbreact';
import { MDBBtn } from 'mdbreact';
import Pagination from 'components/pagination';
import PaginationData from 'components/pagination';
export default class UserList extends Component {
	constructor() {
		super();
		this.state = {
			columns: [],
			row: [],
			paging: {}
		};
		this.columns = [
			{
				label: 'First Name',
				field: 'first_name',
				sort: 'asc',
				width: 150
			},
			{
				label: 'Last Name',
				field: 'last_name',
				sort: 'asc',
				width: 270
			},
			{
				label: 'Company',
				field: 'company_name',
				sort: 'asc',
				width: 200
			},
			{
				label: 'Creation Date',
				field: 'created_at',
				sort: 'asc',
				width: 200
			},
			{
				label: 'Email',
				field: 'email',
				sort: 'asc',
				width: 100
			},
			{
				label: 'Access',
				field: 'check',
				sort: 'asc',
				width: 200
			}
		];

		this.row = [];
	}

	link = userId => {
		this.props.history.push(`account/${userId}`);
	};

	getData = (pageValue, searchData, pageNumber) => {
		let page_size = pageValue ? pageValue : 10;
		let search = searchData ? searchData : '';
		let page = pageNumber ? pageNumber : 1;
		// ?page_size=${page_size}&search=${search}
		this.setState({ row: [this.state.row, ...this.row] });
		ApiService.request(
			'GET',
			`users?page_size=${page_size}&search=${search}&page=${page}`
		).then(response => {
			let data = response.data;
			let paging = response.meta.paging;
			console.log(data);

			let newData = data.map(user => {
				let data = {
					first_name: user.first_name,
					last_name: user.last_name,
					created_at: user.created_at,
					email: user.contact,
					company_name: user.company_name,
					check: (
						<a
							placeholder='Link'
							onClick={() => this.link(user.id)}
							type='button'
							id='checkbox2'>
							Access
						</a>
					)
				};
				return data;
			});

			console.log('mydata is', newData);
			// this.setState({ paging: {...this.state.paging, ...paging} });
			this.setState({
				row: [...newData],
				paging: { ...this.state.paging, ...paging }
			});

			console.log(this.row, 'my name is sandeep singh');
		});
	};

	filter = (name, value) => {
		// alert(name, value);
		// this.setState({ row: [this.state.row, ...this.row] });
		// this.setState({
		// 	row: [...this.state.row, ...this.row]
		// });
		this.getData('', value, '');
		// setTimeout(function(){this.getData("",value,"")}, 3000);
		// this.row.push({
		// 	first_name: 'tiilo Nixon',
		// 	last_name: 'System Architect',
		// 	contact: 'Edinburgh',
		// 	email: '61',
		// 	company_name: '2011/04/25'
		// });
		console.log('statstetstate', this.state);
	};

	pagination = pageValue => {
		let page_size = pageValue;
		this.getData(page_size, '');
	};
	paging = pageNumber => {
		let page_number = pageNumber;
		this.getData('', '', pageNumber);
	};

	componentDidMount() {
		this.getData();
	}
	render() {
		return (
			// <div class='container'>
			// 	<div class='row'>
			// 		<Table
			// 			columns={this.columns}
			// 			row={this.state.row}
			// 			filter={this.filter}></Table>
			// 	</div>
			// </div>
			<div className='container'>
				<div class='col-md-12 user-top'>
					<h1 className='text-center user-head'>Registered Users</h1>
					<Table
						columns={this.columns}
						row={this.state.row}
						link={this.hello}
						pagination={this.pagination}
						page_size={this.state.paging.page_size}
						paging={this.paging}
						filter={this.filter}></Table>
				</div>
			</div>
		);
	}
}
