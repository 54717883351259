import React from 'react';
import PropTypes from 'prop-types';
import ScoreGauge from './ScoreGauge';

const StepScore = props => {
	return (
		<>
			<ScoreGauge total_score={props.total_score} id={props.step_id} />
			<h6 className='text-primary text-center text-size pt-2'>
				{props.step_name}
			</h6>
			<p className='text-size' style={{ color: '#808080', fontWeight: '500' }}>
				{props.total_score} %
			</p>
		</>
	);
};

StepScore.propTypes = {
	total_score: PropTypes.number.isRequired,
	step_id: PropTypes.number.isRequired,
	step_name: PropTypes.string,
	step_desc: PropTypes.string
};

export default StepScore;
