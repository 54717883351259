import React from 'react';
import PropTypes from 'prop-types';

const RatingGroup = props => {
	return <div></div>;
};

RatingGroup.propTypes = {};

export default RatingGroup;
