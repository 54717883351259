import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../pages/Login';
import Register from '../pages/Register';
import { useSelector } from 'react-redux';
import { ForgetPassword } from '../pages/ForgetPassword';
export const Auth = props => {
	const { isUserLoggedIn } = useSelector(state => state.user);

	let { from } =
		props.location.state && props.location.state.from
			? props.location.state
			: { from: { pathname: '/' } };
	if (isUserLoggedIn) {
		return <Redirect to={from} />;
	}
	return (
		<Switch>
			<Route exact path='/login' component={Login} />
			<Route path='/register' exact component={Register} />
			<Route path='/forget-password' exact component={ForgetPassword} />
		</Switch>
	);
};
