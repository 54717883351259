import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumbs = props => {
	return (
		<div className='w-100 bg-secondary pt-5 pb-5 bread-cont'>
			<div className='container' style={{ color: 'white' }}>
				<span className='text-light'>HOME</span>{' '}
				<span className='bread-sep'>|</span> Get your score
			</div>
		</div>
	);
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
