export const API_ENDPOINT_LOGIN = 'users/login';
export const API_ENDPOINT_REGISTER = 'users/register';
export const API_ENDPOINT_USER_SURVEYS = 'surveys/users';
export const API_ENDPOINT_SURVEYS = 'surveys';
export const API_ENDPOINT_USERS = 'users';
export const API_ENDPOINT_LOGOUT = 'logout';
export const API_ENDPOINT_PRODUCTS = 'items';
export const API_ENDPOINT_PRODUCT = 'items';
export const API_ENDPOINT_FACET_ITEMS = 'facet_items';
export const API_ENDPOINT_PRODUCT_DETAIL_FOR_EDIT = 'product-details-edit';
export const API_ENDPOINT_SAVE_PRODUCT = 'save-product';
export const API_ENDPOINT_CATEGORIES_LIST = 'categories-list';
export const API_ENDPOINT_CATEGORY_ATTRIBUTES = 'category-attributes';
export const API_ENDPOINT_UPLOAD_IMAGES = 'images';
export const API_ENDPOINT_SAVE_ADDRESS = 'save-address';
export const API_ENDPOINT_ADDRESS_LIST = 'addresses';
export const API_ENDPOINT_SELLER_ACCOUNT = 'seller-account';
//export const API_ENDPOINT_SELLER_PROFILE = 'seller-account/profile';
export const API_ENDPOINT_CREATE_SELLER = 'seller';
export const API_ENDPOINT_SWAPS = 'swaps';
export const API_ENDPOINT_SHIPPING = 'shipping';
export const API_ENDPOINT_POST_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHODS = 'payment-methods';
export const API_ENDPOINT_DELETE_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_CATEGORIES = 'categories';
export const API_ENDPOINT_UPDATEPASSWORD = 'users/update-password';
export const API_ENDPOINT_FORGOTPASSWORD = 'users/forgot-password';
export const API_ENDPOINT_USERS_SETTING = 'users/settings';
export const API_ENDPOINT_USERS_LIST = 'user-list';
//export const transactionsEndpoint = ()
