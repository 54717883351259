import React from 'react';
import PropTypes from 'prop-types';
import StepScore from './StepScore';

const Report = ({ report = [] }) => {
	return (
		<div className='container pt-5 pb-5 key-metric-cont'>
			<div className='print-logo text-center mb-4 d-none'>
				<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/logo.png' />
			</div>
			<h2 className='text-center font-weight-bold bmain-tit'>Key Metrics</h2>
			<div className='d-flex w-100 flex-wrap mt-4 w-100 justify-content-center'>
				{report.map(stepReport => (
					<div
						className='col-sm-6 col-md-4 col-lg-4 mb-3 d-flex flex-column align-items-center single-meter'
						key={stepReport.step_id}>
						<StepScore
							id={stepReport.step_id}
							step_id={stepReport.step_id}
							total_score={stepReport.total}
							step_name={stepReport.name}
							step_desc={stepReport.desc}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

Report.propTypes = {
	report: PropTypes.array.isRequired
};

export default Report;
