import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import GaugeChart from 'react-gauge-chart';
import './scoreGauge.scss';
const ScoreGauge = props => {
	const [rotation, setRotation] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setRotation(Math.floor((props.total_score / 100) * 180 - 90));
		}, 1000);
	}, []);
	return (
		<div className='meter-main'>
			<div className='meter-outer'>
				<p className='health-txt'>
					Busiess Health <br></br>Checker
				</p>
				<div className='meter-wrapper'>
					<div className='meter-inner'>
						<div className='circle-inner'>
							<div className='circle-content'>
								<div className='needle-div'>
									<div className='needle-main'>
										<div
											className='triangle-up'
											style={{
												transform: `rotate(${rotation}deg)`
											}}></div>
										<div className='need-circle'></div>
									</div>
									{/* <img
										style={{
											transform: `rotate(${rotation}deg)`,
											marginLeft: rotation < 0 ? '20px' : 0,
											marginRight: rotation > 0 ? '20px' : 0
										}}
										src='https://bhc.dukeheightsdev.ca/wp-content/uploads/2020/03/needle.png'
									/> */}
								</div>
								<div className='du-logo'>
									<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/03/image.jpg' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		// <img src='https://via.placeholder.com/200x200' />
		// <GaugeChart
		// 	percent={props.total_score / 100}
		// 	id={`gauge-${props.id}`}
		// 	arcPadding={0}
		// 	cornerRadius={0}
		// />
	);
	// <div className='box gauge--2'>
	// 	{props.total_score}
	// 	<div className='mask'>
	// 		<div className='semi-circle'></div>
	// 		<div className='semi-circle--mask'></div>
	// 	</div>
	// </div>
};

ScoreGauge.propTypes = {
	id: PropTypes.any,
	total_score: PropTypes.number
};

export default ScoreGauge;
