import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = props => {
	return <div></div>;
};

Dropdown.propTypes = {
	value: PropTypes.any,
	error: PropTypes.bool,
	touched: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired
};

export default Dropdown;
