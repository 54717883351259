import React from 'react';

// export const formatDate = (isoDate, withTime = false) => {
// 	const date = new Date(Date.parse(isoDate));
// 	return (
// 		date.toLocaleString('en-us', { month: 'short' }) +
// 		' ' +
// 		date.getDate() +
// 		(withTime ? `, ${date.getHours()}:${date.getMinutes()}` : '')
// 	);
// };
const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
export const formatTime = isoDate => {
	const date = new Date(Date.parse(isoDate));
	return `${date.getHours()}:${date.getMinutes()}`;
};

export function formatDate(date) {
	if (!date) {
		return '';
	}
	var day = date.getDate();
	var monthIndex = date.getMonth();
	var year = date.getFullYear();

	return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export function debounce(func, wait, immediate) {
	var timeout;

	return function() {
		var context = this,
			args = arguments;

		var later = function() {
			timeout = null;

			//func will be our fetchData function adn args will be arguments of fetchData function
			if (!immediate) func.apply(context, args);
		};

		var callNow = immediate && !timeout;

		//hold the function execution setup by setTimeout
		clearTimeout(timeout);

		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
