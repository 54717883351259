import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import InputFeedback from './InputFeedback';

const Checkbox = ({
	field: { name, value, onChange, onBlur },
	id,
	label,
	...props
}) => {
	return (
		<div className='form-check'>
			<input
				name={name}
				type='checkbox'
				value={id} // could be something else for output?
				checked={value.includes(id)}
				onChange={onChange}
				onBlur={onBlur}
				form-check-label
				className='form-check-input'
				{...props}
			/>
			<label className='form-check-label' htmlFor={id}>
				{label}
			</label>
		</div>
	);
};
const CheckboxGroup = ({
	index,
	value,
	error,
	touched,
	id,
	label,
	options = []
}) => {
	return options.map(option => (
		<Field component={Checkbox} name={id} id={option.id} label={option.option} />
	));
};

CheckboxGroup.propTypes = {
	index: PropTypes.number.isRequired,
	value: PropTypes.any,
	error: PropTypes.any,
	touched: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired
};

export default CheckboxGroup;
