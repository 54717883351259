import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './inputs/TextInput';
import RadioButtonGroup from './inputs/RadioButtonGroup';
import CheckboxGroup from './inputs/CheckboxGroup';
import MultiSelect from './inputs/MultiSelect';
import Dropdown from './inputs/Dropdown';
import RatingGroup from './inputs/RatingGroup';
import InputFeedback from './inputs/InputFeedback';

const getInputComponent = type => {
	switch (type) {
		case 'checkbox':
			return CheckboxGroup;
		case 'text':
			return TextInput;
		case 'radio':
		case 'rating':
			return RadioButtonGroup;

		case 'multi-select':
			return MultiSelect;
		case 'dropdown':
			return Dropdown;
		default:
			return RatingGroup;
			break;
	}
};
const SurveyQuestion = ({ question, touched, errors, values, index }) => {
	const { input_type, options, id } = question;
	const question_id = `question_${id}`;
	const InputComponent = getInputComponent(input_type);

	return (
		<div className='form-group'>
			<legend>
				{index}. {question.title}
			</legend>
			<InputComponent
				index={index}
				touched={touched[question_id]}
				error={errors[question_id]}
				id={question_id}
				label={question.title}
				options={options}
			/>
			{touched[question_id] && <InputFeedback error={errors[question_id]} />}
		</div>
	);
};

SurveyQuestion.propTypes = {
	question: PropTypes.object.isRequired,
	values: PropTypes.any.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired
};

export default SurveyQuestion;
