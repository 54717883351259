import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { MDBCol } from 'mdbreact';
import Pagination from './pagination';
// import Pagination from './pageHandler';
import { MDBBtn } from 'mdbreact';
import { MDBPagination, MDBPageItem, MDBPageNav, MDBRow } from 'mdbreact';
import { object } from 'yup';
// import PaginationData from './pagination';
const Table = props => {
	console.log(props, '###########');

	let page_size = props.page_size ? props.page_size : 10;
	const data = {
		columns: props.columns,
		rows: props.row
	};

	const search = e => {
		// alert(props.page_size)
		props.filter(e.target.name, e.target.value);
	};
	const handleChange = e => {
		props.pagination(e.target.value);
	};

	return (
		<div style={{ border: 'none' }}>
			{/* <MDBCol>
				<div className=''>
					<input
						className='form-control'
						type='text'
						placeholder='Search'
						aria-label='Search'
						name='search'
						onChange={search}
					/>
				</div>
			</MDBCol> */}

			<div className='search-div'>
				<input
					width={20}
					className='form-control user-search'
					type='text'
					placeholder='Search'
					aria-label='Search'
					name='search'
					onChange={search}
				/>

				{/* Working */}
				{/* <i class='fa fa-search'></i> */}

				{/* <i class='glyphicon glyphicon-user'></i> */}
			</div>
			<div class='table-responsive'>
				<table class='table'>
					<thead>
						<tr>
							{props.columns.map((col, key) => {
								return <th className='table-head-style'>{col.label}</th>;
							})}
						</tr>
						<tr>
							{props.row.length == 0 ? 'No matching records found' : null}
							{/* <div class='spinner-border' text-center role='status'>
								<span class='sr-only'>Loa.toLocaleDateString("en-US")ding...</span>
							</div> */}
						</tr>
					</thead>

					<tbody>
						{props.row.map((user, key) => {
							return (
								// <tr>{user.first_name}</tr>

								<tr>
									{/* {user.length == 0 ? <h1>Not found</h1> : ''} */}
									{/* <th scope="row">{user.first_name}</th> */}
									<td>{user.first_name}</td>
									<td>{user.last_name}</td>
									<td>{user.company_name}</td>
									<td>{new Date(user.created_at).toLocaleDateString('en-US')}</td>
									<td>{user.email}</td>
									<td>
										<a style={{ boxShadow: 'none', color: 'green' }}>{user.check}</a>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between'
				}}>
				<div>
					<Pagination paging={props.paging} />
				</div>

				<div>
					<select
						className='browser-default custom-select'
						value={page_size}
						onChange={handleChange}>
						<option>Choose your option</option>
						<option value='10'>10</option>
						<option value='20'>20</option>
						<option value='30'>30</option>
						<option value='40'>40</option>
					</select>
				</div>
			</div>

			{/* <MDBDataTable
				responsive
				className='ResponsiveTable'
				searching={false}
				paging={false}
				sorting={false}
				footer={false}
				showPagination={false}
				data={data}
			/> */}

			{/* <MDBRow>
				<MDBCol>
					<MDBPagination circle>
						<MDBPageItem disabled onclick={() => first}>
							<MDBPageNav className='page-link'>
								<span>First</span>
							</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem disabled>
							<MDBPageNav className='page-link' aria-label='Previous'>
								<span aria-hidden='true'>&laquo;</span>
								<span className='sr-only'>Previous</span>
							</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem active={currentValue == 1 ? true : false}>
							<MDBPageNav className='page-link'>
								1 <span className='sr-only'>(current)</span>
							</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem active={currentValue == 5 ? true : false}>
							<MDBPageNav className='page-link'>5</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem active={currentValue == 10 ? true : false}>
							<MDBPageNav className='page-link'>10</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem active={currentValue == 15 ? true : false}>
							<MDBPageNav className='page-link'>15</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem active={currentValue == 20 ? true : false}>
							<MDBPageNav className='page-link'>20</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem>
							<MDBPageNav className='page-link'>&raquo;</MDBPageNav>
						</MDBPageItem>
						<MDBPageItem>
							<MDBPageNav className='page-link' onclick={() => last}>
								Last
							</MDBPageNav>
						</MDBPageItem>
					</MDBPagination>
				</MDBCol>
				<MDBCol> 
				<Pagination/>
				</MDBCol> 
				<div>
					<select className='browser-default custom-select' onChange={handleChange}>
						<option>Choose your option</option>
						<option value='10' selected>
							10
						</option>
						<option value='20'>20</option>
						<option value='30'>30</option>
						<option value='40'>40</option>
					</select>
				</div>
				
			</MDBRow> */}

			{/* <Pagination></Pagination> */}
		</div>
	);
};

export default Table;
