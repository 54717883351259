import React, { Component } from 'react';
const PasswordSuccess = props => {
	return (
		<>
			<div className='container login-cont create-acc-cont'>
				<div className='row align-middle mt-5 mb-5'>
					<div className='col-md-6 reset-column'>
						<div className='card bg-light-blue'>
							<div className='card-body password-success-body'>
								<div className='card-title'>
									<h1 className='mb-4 text-center'>Password Changed Successfully</h1>
									<p className='text-center'>
										Please <a href='/login'>click</a> here to login with your new
										credentials
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PasswordSuccess;
