import React from 'react'

const PageNotFound = () => {
  return (
    <div className='wrapper' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <span style={{fontWeight:'bold',fontSize:'29px',display:'flex',justifyContent:'center',alignItems:'center'}}>
            Sorry,page not found.
        </span>
    </div>
  )
}

export default PageNotFound