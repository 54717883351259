import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeaderMenu from './HeaderMenu';
import Breadcrumbs from './Breadcrumbs';

const Header = props => {
	const [showMobileMenu, toggleMobileMenu] = useState(false);
	return (
		<>
			<div className='header'>
				<header className='position-relative container'>
					<nav className='navbar navbar-expand-lg navbar-light '>
						<a className='navbar-brand' href='https://bhc.dukeheights.ca/'>
							<img
								src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/logo.png'
								className='d-inline-block align-top'
							/>
							<span className='head-bus-txt'>
								Business
								<br /> Health
								<br /> Checker
							</span>
						</a>
						<button
							className='navbar-toggler'
							type='button'
							data-toggle='collapse'
							data-target='#navbarNav'
							aria-controls='navbarNav'
							aria-expanded='false'
							onClick={() => toggleMobileMenu(!showMobileMenu)}
							aria-label='Toggle navigation'>
							<span className='navbar-toggler-icon'></span>
						</button>
						<HeaderMenu showMobileMenu={showMobileMenu} />
					</nav>
				</header>
				<Breadcrumbs />
			</div>
		</>
	);
};

Header.propTypes = {};

export default Header;
