import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
	// first_name: Yup.string().required('First Name is required'),
	// last_name: Yup.string().required('Last Name is required'),

	//company_name: Yup.string().required('Please input birth date'),

	email: Yup.string().email('Please enter a valid Email address'),
	// .required('Please enter a valid email address'),
	contact: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number')
	// .required('Please enter a valid phone number')
});
export default function PersonalInformation(props) {
	const onSubmit = async (values, actions) => {
		// if (values.first_name == '') {
		// 	delete values.first_name;
		// }
		// if (values.last_name == '') {
		// 	delete values.last_name;
		// }
		// if (values.email == '') {
		// 	delete values.email;
		// }
		// if (values.contact == '') {
		// 	delete values.contact;
		// }
		Object.keys(values).forEach(key => values[key] == '' && delete values[key]);
		try {
			await props.saveForm(values);
			actions.setSubmitting(false);
			alert('User information updated !');
			actions.resetForm();
		} catch (err) {
			actions.setSubmitting(false);
		}
	};
	return (
		<div className='form-box personal mt-4'>
			<div className='disclamer'>
				<div>Disclamer: All information provided is confidential, secure and will never be shared with any third party.</div>
			</div>
			<div className='form-title font-weight-bold text-uppercase mb-3'>
				Personal Information
			</div>
			<div className='row'>
				<div className='col-md-4'>
					Tell us a little about you and your preferred contact information
				</div>
				<div className='col-md-8'>
					<Formik
						validationSchema={validationSchema}
						initialValues={{
							first_name: '',
							last_name: '',
							email: '',
							contact: ''
						}}
						onSubmit={onSubmit}>
						{({ errors, touched, isValid, isSubmitting, resetForm }) => (
							<>
								<Form>
									<div className='form-row'>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='first_name'
												placeholder='First Name'
												className='form-control'
												//required
											/>

											{errors.first_name && touched.first_name && (
												<small>{errors.first_name}</small>
											)}
										</div>
										<div className='form-group col-sm-6'>
											<Field
												type='text'
												name='last_name'
												placeholder='Last Name'
												className='form-control'
											/>

											{errors.last_name && touched.last_name && (
												<small>{errors.last_name}</small>
											)}
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-sm-6'>
											<Field
												type='email'
												name='email'
												placeholder='Email'
												className='form-control'
												//required
											/>

											{errors.email && touched.email && <small>{errors.email}</small>}
										</div>

										<div className='form-group col-sm-6'>
											<Field
												type='tel'
												name='contact'
												placeholder='Phone'
												className='form-control'
											/>

											{errors.contact && touched.contact && (
												<small>{errors.contact}</small>
											)}
										</div>
									</div>
									{/* {isSubmitting ? alert('Information Updated') : null} */}
									<div className='d-flex justify-content-end'>
										<button
											className='btn btn-lg btn-primary save-button'
											type='submit'
											disabled={!isValid || isSubmitting}>
											SAVE
										</button>
									</div>
								</Form>
							</>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
