import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageLink } from 'lib/pageLink';
import ScoreGauge from './ScoreGauge';

const TotalScore = props => {
	const user = useSelector(state => state.user);
	const history = useHistory();
	return (
		<div className='survey-total-score pb-5 bg-light-blue'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 cust-info'>
						<h2 className='text-primary font-weight-bold text-capitalize mb-4'>
							Dear {user.first_name} {user.last_name}
						</h2>
						<p>
							Getting a better understanding of your business is the first step in finding ways to improve your business and beat the competition. 
						</p>
						<p>
							We hope that the Business Health-Check provides some insight into the business and helps to create a forward-looking growth road map that helps position the business for greater success.
						</p>
						<p>
							As with all tools, the Business Health-Check should be used as part of a suite of tools to help with business decision making. The Business Health-Check is not meant to be used as a sole source for business advise, and a professional business advisor should be consulted before making any major decisions for your business. 
						</p>
						<div className='align-self-center'>
							{props.show_details ? (
								<button
									type='button'
									className='btn btn-primary btn-lg'
									onClick={() => history.push(PageLink.surveyReport(props.survey_id))}>
									More Details
								</button>
							) : (
								<button
									type='button'
									className='btn btn-lg btn-primary mt-3'
									onClick={() => window.print()}>
									Download Report
								</button>
							)}
						</div>
					</div>
					<div className='col-md-6 score-ridiv'>
						<div className='total_score'>
							<p className='score-num'>
								<span>{props.total_score}%</span>
							</p>
							<p className='readsci'>Reading about Science</p>
						</div>
						<div className='score-img-div'>
							<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/03/total-score.png'></img>
						</div>

						<div className='total-meter'>
							<ScoreGauge total_score={props.total_score} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

TotalScore.defaultProps = {
	show_details: true
};
TotalScore.propTypes = {
	total_score: PropTypes.any.isRequired,
	survey_id: PropTypes.any.isRequired,
	show_details: PropTypes.bool
};

export default TotalScore;
