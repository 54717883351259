import React from 'react';
import './App.css';
import { Provider, useSelector } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Shared from './components/Shared';
import UserAccount from './pages/UserAccount';
import UserList from './pages/UserList';
import Modals from './components/modals/Modals';
import Toasts from './components/toasts/Toasts';
import SurveyForm from './pages/SurveyForm';
import SurveyReport from './pages/SurveyReport';
import SurveyScore from './pages/SurveyScore';
import Register from './pages/Register';
import { Auth } from './components/Auth';
import './lib/fontawesomeLibrary';
import { ForgetPassword } from 'pages/ForgetPassword';
import ResetPassword from 'pages/resetPassword';
import { ResetMessage } from 'pages/ResetMessage';
import PasswordSuccess from 'pages/PasswordSuccess';
import PageNotFound from 'pages/PageNotFound';
const rootPath = '/';
function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Shared>
					<Switch>
						<Route exact path='/login' component={Auth} />
						<Route exact path='/email-password' component={ForgetPassword} />
						<Route exact path='/forget-password/:token' component={ResetPassword} />
						<Route exact path='/reset-message' component={ResetMessage} />
						<Route
							exact
							path='/password-update-success'
							component={PasswordSuccess}
						/>

						<Route path='/register' exact component={Auth} />
						<PrivateRoute path='/account/:user_id?' exact component={UserAccount} />
						<PrivateRoute path='/users' exact component={UserList} />
						<PrivateRoute
							path='/surveys/:survey_id/report'
							exact
							component={SurveyReport}
						/>
						<PrivateRoute
							path='/surveys/:survey_id/score'
							exact
							component={SurveyScore}
						/>
						<PrivateRoute
							path='/surveys/:survey_id/:step?'
							exact
							component={SurveyForm}
						/>
						<AdminPrivateRoute path='/user-list' component={UserList} />
						{/* <PrivateRoute path='/user-list' exact component={UserList} /> */}
						<PrivateRoute path='/' exact component={UserAccount} />
						
						<PrivateRoute path='*' exact component={PageNotFound}/>
					</Switch>
				</Shared>
				<Modals />
				<Toasts />
			</BrowserRouter>
		</Provider>
	);
}
const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isUserLoggedIn } = useSelector(state => state.user);
	// console.log(isUserLoggedIn);
	return (
		<Route
			{...rest}
			render={props =>
				!isUserLoggedIn ? (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location }
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

const AdminPrivateRoute = props => {
	const { component, path } = props;
	const { role } = useSelector(state => state.user);

	if (role) {
		return role == 'admin' ? (
			<>
				<PrivateRoute path={path} exact component={component} />
				{/* <PrivateRoute path={path} exact component={component} /> */}
			</>
		) : (
			<Route
				render={props => (
					<Redirect
						to={{
							pathname: '/account'
							// state: { from: props.location }
						}}
					/>
				)}
			/>
		);
	} else {
		return <PrivateRoute path={path} exact component={component} />;
	}

	// alert(role);
};
export default App;
