import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '../css/custom.scss';
import AuthService from '../lib/AuthService';
import Header from './header/Header';
import Footer from './Footer';
import { Provider, useSelector } from 'react-redux';

const Shared = props => {
	useEffect(() => {
		AuthService.setUserAuthOnPageLoad();
	}, []);
	// const {
	//   user: { isUserLoggedIn }
	// } = props;

	// if (!isUserLoggedIn) {
	//   return <Redirect to='/login' />;
	// }
	const { isUserLoggedIn } = useSelector(state => state.user);
	return (
		<>
			{isUserLoggedIn ? <Header /> : null}
			{/* <Header /> */}
			<div className='wrapper'>{props.children}</div>
			<Footer />
		</>
	);
};
const mapStateToProps = state => {
	return { user: state.user };
};
export default connect(mapStateToProps, null)(Shared);
