import React, { Component } from 'react';

export const ResetMessage = props => {
	return (
		<>
			<div className='container login-cont create-acc-cont '>
				<div className='row align-middle mt-5 mb-5'>
					<div className='col-md-6 reset-column'>
						<div className='card bg-light-blue'>
							<div className='card-body reset-card-body'>
								<div className='card-title'>
									<h1 className='text-center reset-link mb-4'>
										A password reset link has been sent
									</h1>
									<p className='text-center'>
										Email with instruction was sent to your email address.
										<br /> Please check it!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
