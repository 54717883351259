import React from 'react';
import { render } from 'react-dom';
import Pagination from 'react-paginating';
// import Pagination from 'components/pagination';

const limit = 1;
const total = 100;

class PaginationData extends React.Component {
	constructor() {
		super();
		this.state = {
			currentPage: 1
			// total:this.props.pageSize
		};
	}

	handlePageChange = (page, e) => {
		e.preventDefault();

		this.setState(
			{
				currentPage: page
			},
			() => {
				this.props.paging(this.state.currentPage);
			}
		);
	};

	render() {
		const { currentPage } = this.state;

		return (
			<div>
				{/* <ul>
          {fruits[currentPage - 1].map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul> */}
				<Pagination
					total={total}
					limit={limit}
					currentPage={currentPage}
					className='bg-red'>
					{({
						pages,
						currentPage,
						hasNextPage,
						hasPreviousPage,
						previousPage,
						nextPage,
						totalPages,
						getPageItemProps
					}) => (
						<nav aria-label='Page navigation example'>
							<ul class='pagination'>
								<li
									{...getPageItemProps({
										pageValue: 1,
										onPageChange: this.handlePageChange
									})}>
									<a class='page-link' href='#'>
										Previous
									</a>
								</li>

								{hasPreviousPage && (
									<li
										{...getPageItemProps({
											pageValue: previousPage,
											onPageChange: this.handlePageChange
										})}>
										<a class='page-link' href='#'>
											{'<'}
										</a>
									</li>
								)}

								{pages.map(page => {
									let activePage = null;
									if (currentPage === page) {
										activePage = { backgroundColor: '#fdce09' };
									}
									return (
										<li
											{...getPageItemProps({
												pageValue: page,
												key: page,
												style: activePage,
												onPageChange: this.handlePageChange
											})}>
											<a class='page-link' href='#'>
												{page}
											</a>
										</li>
									);
								})}

								{hasNextPage && (
									<li
										{...getPageItemProps({
											pageValue: nextPage,
											onPageChange: this.handlePageChange
										})}>
										<a class='page-link' href='#'>
											{'>'}
										</a>
									</li>
								)}

								<li
									{...getPageItemProps({
										pageValue: totalPages,
										onPageChange: this.handlePageChange
									})}>
									<a class='page-link' href='#'>
										last
									</a>
								</li>
							</ul>
						</nav>
					)}
				</Pagination>
			</div>
		);
	}
}

export default PaginationData;
