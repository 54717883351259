import React, { useEffect, useState } from 'react';
import ApiService from 'lib/ApiService';
import Loading from '../components/Loading';
import TotalScore from '../components/surveyReport/TotalScore';
import SurveyReportBox from '../components/surveyReport/SurveyReportBox';

const SurveyScore = props => {
	const { survey_id } = props.match.params;
	const [isLoading, setIsLoading] = useState(true);
	const [totalScore, setTotalScore] = useState(null);
	useEffect(() => {
		ApiService.request('GET', `surveys/${survey_id}/total_score`).then(
			response => {
				setTotalScore(response.data);
				setIsLoading(false);
			}
		);
	}, []);
	if (isLoading) {
		return <Loading />;
	}

	return totalScore !== null ? (
		<section className='survey-report bg-light-blue h-100 pb-5'>
			<TotalScore survey_id={survey_id} total_score={totalScore} />
			<SurveyReportBox
				survey_id={survey_id}
				total_score={totalScore}
				background={'bg-light-blue'}
			/>
		</section>
	) : null;
};

export default SurveyScore;
