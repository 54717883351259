import store from '../redux/store';
import ApiService from './ApiService';
import { userLogin, userLogut } from '../redux/actions/userAction';
import {
	API_ENDPOINT_LOGIN,
	API_ENDPOINT_REGISTER,
	API_ENDPOINT_FORGOTPASSWORD,
	API_ENDPOINT_UPDATEPASSWORD
} from './apiEndpoints';

/* eslint-disable no-restricted-globals */

const LOGIN_PATH = '/login';
const HOME_PATH = '/';

export const AuthService = {
	setUserAuthOnPageLoad() {
		const user = this.isLoggedIn();
		if (user) {
			store.dispatch(
				userLogin({
					...user
				})
			);
		}
	},
	isLoggedIn() {
		//check redux or localstorage to check if user is logged in
		const user = JSON.parse(localStorage.getItem('bhc_user'));
		return !user || Object.entries(user).length === 0 ? false : user;
	},

	authenticate(creds) {
		// call login API
		return new Promise((resolve, reject) => {
			ApiService.request('POST', API_ENDPOINT_LOGIN, creds)
				.then(resp => {
					setUserDetails(resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	update(creds) {
		return new Promise((resolve, reject) => {
			ApiService.request('POST', API_ENDPOINT_UPDATEPASSWORD, creds, false)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	passwordUpdateEmail(creds) {
		return new Promise((resolve, reject) => {
			ApiService.request('POST', API_ENDPOINT_FORGOTPASSWORD, creds, false)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	register(creds) {
		return new Promise((resolve, reject) => {
			ApiService.request('POST', API_ENDPOINT_REGISTER, creds, false)
				.then(resp => {
					setUserDetails(resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		// comment the below line when linked with Login API

		//setTimeout(successCallback);
	},

	signout(cb) {
		//call logout API
		localStorage.setItem('bhc_user', JSON.stringify({}));
		store.dispatch(userLogut());
		setTimeout(cb);
	}
};
export const setUserDetails = (user = {}) => {
	localStorage.setItem('bhc_user', JSON.stringify(user));
	store.dispatch(userLogin({ ...user }));
};
export default AuthService;
