import React from 'react';
import PropTypes from 'prop-types';

const MultiSelect = props => {
	return <div></div>;
};

MultiSelect.propTypes = {
	value: PropTypes.any,
	error: PropTypes.bool,
	touched: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired
};

export default MultiSelect;
