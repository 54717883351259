import React from 'react';
import PropTypes from 'prop-types';

const SurveyProgress = ({ steps }) => {
	const totalStepsCompleted = steps.reduce((total, step) => {
		if (step.status) {
			return total + 1;
		}
		return total;
	}, 0);
	const progressPercent = Math.floor((totalStepsCompleted / steps.length) * 100);
	return (
		<>
			<p className='prog-bar'>{progressPercent}% completed</p>
			<div className='progress' style={{ height: '3px' }}>
				<div
					className='progress-bar'
					role='progressbar'
					style={{ width: `${progressPercent}%` }}
					aria-valuenow='25'
					aria-valuemin='0'
					aria-valuemax='100'></div>
			</div>
		</>
	);
};

SurveyProgress.propTypes = {
	steps: PropTypes.array.isRequired
};

export default SurveyProgress;
