import React from 'react';
import PropTypes from 'prop-types';
import PersonalInformation from './PersonalInformation';
import ApiService from 'lib/ApiService';
import Password from './Password';
import Company from './Company';
import store from 'redux/store';
import { setUserUpdate, userLogin } from '../../redux/actions/userAction';
// import Company from './Company';
// import Password from './Password';

const Account = ({ user_id, user }) => {
	const saveForm = (values) => {
		// alert('fesgd');

		return ApiService.request('PUT', `users/${user_id}`, values, true).then(
			(resp) => {
				if (resp.status) {
					localStorage.setItem(
						'bhc_user',
						JSON.stringify({ ...user, ...resp.data })
					);
					store.dispatch(setUserUpdate({ ...values }));
				}
			}
		);
	};
	// const changePassword = values => {
	// 	return ApiService.request('PUT',`users/${user_id}/password`, values);
	// };

	return (
		<div className='user-account-form-wrapper mb-5'>
			<PersonalInformation user_id={user_id} saveForm={saveForm} />
			<Password user_id={user_id} />
			<Company user_id={user_id} />
		</div>
	);
};

Account.propTypes = {
	user_id: PropTypes.number.isRequired,
};

export default Account;
