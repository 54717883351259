import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { API_ENDPOINT_SURVEYS } from '../lib/apiEndpoints';
import ApiService from '../lib/ApiService';
import { formatDate } from '../lib/helper';
import { PageLink } from '../lib/pageLink';
import SurveyReportBox from './surveyReport/SurveyReportBox';
function SurveyList(props) {
	const { surveys, history } = props;
	console.log("props",props)
	let completedSurveys = [];
	const [latestSurvey, setLatestSurvey] = useState(null);
	useEffect(() => {
		if (surveys.length > 0) {
			setLatestSurvey(surveys[0]);
		}
	}, [surveys]);

	const onDownload = (event, survey_id) => {
		//event.stopPropogation();
		history.push(PageLink.surveyReport(survey_id));
	};
	const loadSurveyForm = useCallback(() => {
		history.push(PageLink.surveyForm(latestSurvey.id));
	}, [latestSurvey]);
	const createNewSurvey = () => {
		ApiService.request('POST', API_ENDPOINT_SURVEYS).then(response => {
			const { survey_id } = response.data;
			history.push(PageLink.surveyForm(survey_id));
		});
	};

	// if (!surveys || surveys.length === 0) {
	// 	return (
	// 		<button
	// 			type='button'
	// 			className='btn btn-primary rounded'
	// 			onClick={createNewSurvey}>
	// 			Start New Test
	// 		</button>
	// 	);
	// }

	return (
		<>
			{/* <SurveyReportBox
				survey_id={latestSurvey}
				total_score={latestSurvey.total}
				className='test'
			/> */}

			{!latestSurvey || latestSurvey.status <= 0 ? null : (
				<SurveyReportBox
					survey_id={latestSurvey}
					total_score={latestSurvey.total}
					viewresult={true}
					className='test'
				/>
			)}
			<div className='list-group list-group-flush mt-4 score-list'>
				{surveys.slice(1, surveys.length).map(survey => {
					return (
						<a
							className='list-group-item list-group-item-action '
							key={survey.id}
							onClick={() => history.push(PageLink.surveyReport(survey.id))}>
							<div className='d-flex w-100'>
								<div className='total-score col-5 text-danger font-weight-bold'>
									{survey.total}%
								</div>
								{/* <div className='col-2'></div> */}
								<div className='start-date col-5'>
									{formatDate(new Date(survey.created_at))}
								</div>
								{/* <div className='col-1'></div> */}
								<div
									className='download-link col-2'
									onClick={onDownload.bind(survey.id)}>
									View Results
								</div>
							</div>
						</a>
					);
				})}
			</div>
			<div className='container stopwat-div'>
				<div className='row banner'>
					<div className='col-md-6 banner-div-one'>
						<img src='https://bhc.dukeheights.ca/wp-content/uploads/2020/02/Mask-Group.svg'></img>
					</div>
					<div className='col-md-6 banner-div-two '>
						<h2 className='stop-wasting'>Let's get started</h2>

						{!latestSurvey || latestSurvey.status ? (
							<div className='btn-group'>
								{/* <SurveyReportBox
									survey_id={latestSurvey}
									total_score={latestSurvey.total}
								/> */}
								<button className='btn btn-primary' onClick={createNewSurvey}>
									Start New Survey
								</button>
							</div>
						) : (
							<button className='btn btn-primary rounded' onClick={loadSurveyForm}>
								Continue Survey
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

SurveyList.propTypes = {
	surveys: PropTypes.array
};

export default withRouter(SurveyList);
