import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import AuthService from '../lib/AuthService';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const RegisterValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	// username: Yup.string().required('UserName is required'),
	company_name: Yup.string().required('Please input company name'),
	email: Yup.string().required('Please enter a valid email address'),
	contact: Yup.string()
		.matches(phoneRegExp, 'Please enter a valid phone number')
		.required('Please enter a valid phone number')
		.max(10, 'Please enter a valid phone number'),
	password: Yup.string()
		.required('Password is required')
		.min(6, 'Password needs to minimum 6 characters'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Please re-enter password'),
});

const Register = (props) => {
	const [errorMessage, setErrorMessage] = useState(null);
	const onFormSubmit = (values, actions) => {
		let data = {
			...values,
			email: values.email.toLocaleLowerCase(),
			first_name: values.first_name.toLocaleLowerCase(),
			last_name: values.last_name.toLocaleLowerCase(),
		};
		//alert(JSON.stringify(values));
		AuthService.register(data)
			.then((resp) => {
				alert('Account created successfully !');
				// actions.resetForm();
			})
			.catch((err) => setErrorMessage(err.message))
			.finally(() => actions.setSubmitting(false));
	};

	return (
		<>
			<div className='container login-cont create-acc-cont'>
				<div className='row mt-5 mb-5 mt-5'>
					<div className='col-md-6'>
						<h1 className='get-your-score bmain-tit' style={{ fontWeight: '700' }}>
							Get Your Score
						</h1>
						<br />
						<p>
							The Business Health-Check is very easy to use and has cross functional way
							of evaluating business
						</p>
						<br />
						<p>
							<b>- Duke Heights BIA Member</b>
						</p>
						<div className='image-score'>
							<img
							style={{maxWidth:'100%'}}
								src='https://bhc.dukeheights.ca/wp-content/uploads/2020/03/img_2.png'
								alt='Failed to Load ..'
							/>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='card bg-light-blue'>
							<div className='card-body'>
								<div className='card-title'>
									<h1 className='text-center'>Create An Account</h1>
								</div>
								{errorMessage ? (
									<p className='text-center p-2 text-center border border-danger'>
										{errorMessage}
									</p>
								) : null}
								<Formik
									validationSchema={RegisterValidationSchema}
									initialValues={{
										first_name: '',
										last_name: '',
										username: '',
										company_name: '',
										email: '',
										contact: '',
										password: '',
										confirm_password: '',
									}}
									onSubmit={onFormSubmit}>
									{({ errors, touched, isValid, isSubmitting, resetForm }) => (
										<>
											<Form className='loginreg-frm'>
												<div className='form-group'>
													<Field
														type='text'
														name='first_name'
														placeholder='First Name'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.first_name && errors.first_name,
														})}
														//required
													/>

													{errors.first_name && touched.first_name && (
														<small>{errors.first_name}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='text'
														name='last_name'
														placeholder='Last Name'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.last_name && errors.last_name,
														})}
													/>

													{errors.last_name && touched.last_name && (
														<small>{errors.last_name}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='text'
														name='username'
														placeholder='Username'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.username && errors.username,
														})}
													/>

													{errors.username && touched.username && (
														<small>{errors.username}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='text'
														name='company_name'
														placeholder='Company Name'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.company_name && errors.company_name,
														})}
														//required
													/>

													{errors.company_name && touched.company_name && (
														<small>{errors.company_name}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='email'
														name='email'
														placeholder='Email'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.email && errors.email,
														})}
													/>

													{errors.email && touched.email && <small>{errors.email}</small>}
												</div>
												<div className='form-group'>
													<Field
														type='tel'
														name='contact'
														placeholder='Phone'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.contact && errors.contact,
														})}
													/>

													{errors.contact && touched.contact && (
														<small>{errors.contact}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='password'
														name='password'
														placeholder='Password'
														className={classNames({
															'form-control': true,
															'is-invalid': touched.password && errors.password,
														})}
													/>

													{errors.password && touched.password && (
														<small>{errors.password}</small>
													)}
												</div>
												<div className='form-group'>
													<Field
														type='password'
														name='confirm_password'
														placeholder='Confirm Password'
														className={classNames({
															'form-control': true,
															'is-invalid':
																touched.confirm_password && errors.confirm_password,
														})}
													/>

													{errors.confirm_password && touched.confirm_password && (
														<small>{errors.confirm_password}</small>
													)}
												</div>

												<button
													className='btn btn-primary create-button'
													type='submit'
													disabled={!isValid || isSubmitting}>
													CREATE ACCOUNT
												</button>
												<br></br>
												<div className='account-to'>
													<NavLink to='/Login' className='navigate'>
														Already Have an Account?
													</NavLink>
												</div>
											</Form>
										</>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Register;
