import {
	USER_LOGIN,
	USER_LOGOUT,
	USER_REGISTER,
	SET_USER_ADDRESS,
	USER_UPDATE
} from '../actionTypes';

export const userLogin = payload => {
	return {
		type: USER_LOGIN,
		payload: {
			...payload
		}
	};
};
export const userRegister = payload => {
	return {
		type: USER_REGISTER,
		payload: {
			...payload
		}
	};
};
export const userLogut = () => {
	return {
		type: USER_LOGOUT,
		payload: {}
	};
};
export const setUserAddress = payload => {
	return {
		type: SET_USER_ADDRESS,
		payload: { ...payload }
	};
};
export const setUserUpdate = payload => {
	return {
		type: USER_UPDATE,
		payload: { ...payload }
	};
};
